import React from 'react';
import Activities from './views/pages/activity/Activity';
import ActivitiesDetail from './views/pages/activity/ActivityDetail';
import BusinessField from './views/pages/businessField/BusinessField';
import BusinessFieldDetail from './views/pages/businessField/BusinessFieldDetail';
import Contact from './views/pages/contact/Contact';
import ContactDetail from './views/pages/contact/ContactDetail';
import Media from './views/pages/media/Media';
import MediaDetail from './views/pages/media/MediaDetail';
import Post from './views/pages/post/Post';
import PostDetail from './views/pages/post/PostDetail';
import PostCategory from './views/pages/postCategory/PostCategory';
import PostCategoryDetail from './views/pages/postCategory/PostCategoryDetail';
import Product from './views/pages/product/Product';
import ProductDetail from './views/pages/product/ProductDetail';
import ProductCategory from './views/pages/productCategory/ProductCategory';
import ProductCategoryDetail from './views/pages/productCategory/ProductCategoryDetail';
import Registration from './views/pages/registration/Registration';
import RegistrationDetail from './views/pages/registration/RegistrationDetail';
import Reviews from './views/pages/reviews/Reviews';
import ReviewsDetail from './views/pages/reviews/ReviewsDetail';

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const Dashboard = React.lazy(() => import('./views/pages/dashboard/Dashboard'));
const PotentialCandidate = React.lazy(() =>
  import('./views/pages/potentialCandidate/PotentialCandidate'),
);
const JobManagerment = React.lazy(() => import('./views/pages/jobManagerment/JobManagerment'));
const CvManagerment = React.lazy(() => import('./views/pages/cvManagerment/CvManagerment'));
const ConfigManagerment = React.lazy(() => import('./views/pages/config/Config'));
const BodManagement = React.lazy(() => import('./views/pages/bodManagerment/BodManagerment'));
const IntroductionManagement = React.lazy(() =>
  import('./views/pages/introductionManagement/IntroductionManagement'),
);
const IntroductionDetailManagement = React.lazy(() =>
  import('./views/pages/introductionManagement/IntroductionManagementDetail'),
);

const NewManagement = React.lazy(() => import('./views/pages/newManagement/NewManagement'));
const ServiceManagement = React.lazy(() =>
  import('./views/pages/serviceManagement/ServiceManagement'),
);
const ServiceManagementDetail = React.lazy(() =>
  import('./views/pages/serviceManagement/ServiceManagementDetail'),
);
const allRoutes = [
  {
    path: '/dashboard',
    key: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/potential-candidate',
    name: 'Potential Candidate',
    component: PotentialCandidate,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/job-management',
    name: 'Jobs',
    component: JobManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/cv-management',
    name: 'CV',
    component: CvManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/config',
    name: 'Config',
    component: ConfigManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/bod-management',
    name: 'BOD',
    component: BodManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-management',
    name: 'Service',
    component: ServiceManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-management/:id',
    name: 'Service',
    component: ServiceManagementDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/reviews',
    name: 'Danh sách đánh giá',
    component: Reviews,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/reviews/:id',
    name: 'Tạo đánh giá',
    component: ReviewsDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/activities',
    name: 'Danh sách hoạt động',
    component: Activities,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/activities/:id',
    name: 'Tạo hoạt động',
    component: ActivitiesDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/registrations',
    name: 'Danh sách đăng ký',
    component: Registration,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/registrations/:id',
    name: 'Tạo đăng ký',
    component: RegistrationDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/post',
    name: 'Danh sách bài viết',
    component: Post,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/post/:id',
    name: 'Tạo bài viết',
    component: PostDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/business-field',
    name: 'Danh sách lĩnh vực kinh doanh',
    component: BusinessField,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/business-field/:id',
    name: 'Tạo lĩnh vực kinh doanh',
    component: BusinessFieldDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/contact',
    name: 'Danh sách liên hệ',
    component: Contact,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/contact/:id',
    name: 'Tạo liên hệ',
    component: ContactDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/media',
    name: 'Thư viện',
    component: Media,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/media/:id',
    name: 'Thêm thư viện',
    component: MediaDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/post-category',
    name: 'Danh mục bài viết',
    component: PostCategory,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/post-category/:id',
    name: 'Thêm danh mục bài viết',
    component: PostCategoryDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/product-category',
    name: 'Danh mục sản phẩm',
    component: ProductCategory,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/product-category/:id',
    name: 'Thêm danh mục sản phẩm',
    component: ProductCategoryDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/product',
    name: 'Danh sách sản phẩm',
    component: Product,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/product/:id',
    name: 'Thêm sản phẩm',
    component: ProductDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: IntroductionManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/introduction/:id',
    name: 'Introduction',
    component: IntroductionDetailManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/news-management',
    name: 'News',
    component: NewManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/login',
    key: 'Login',
    name: 'Login',
    component: Login,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/register',
    key: 'Register',
    name: 'Register',
    component: Register,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/404',
    key: 'Page404',
    name: 'Page404',
    component: Page404,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/500',
    key: 'Page500',
    name: 'Page500',
    component: Page500,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
];

export const routes = allRoutes.reduce((obj, item) => {
  const temp = { ...obj };
  if (item.key) temp[item.key.toLowerCase()] = item;
  return temp;
}, {});

export default allRoutes;
