const MediaTypes = {
  IMAGE: {
    value: 0,
    name: 'Hình ảnh',
  },
  VIDEO: {
    value: 1,
    name: 'Video',
  },
  DOCUMENT: {
    value: 2,
    name: 'Tài liệu',
  },
};

export default MediaTypes;
