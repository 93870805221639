/* eslint-disable radix */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from 'src/api/api-client';
import { detailBusinessFieldURI, businessFieldURI } from '../api';
import { setDefaultValue } from './BusinessFieldItemSlice';
import { setValue } from './MessageSlice';

// First, create the thunk
export const fetchBusinessFields = createAsyncThunk(
  'businessField/fetchBusinessFields',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiClient
        .handleRequest({
          method: 'get',
          url: `${businessFieldURI}`,
          params: { ...params, show_all: true },
        })
        .then(res => res.data);
      return response.data;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const detailBusinessField = createAsyncThunk(
  'businessField/detailBusinessField',
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await apiClient.handleRequest({
        method: 'get',
        url: `${detailBusinessFieldURI(id)}`,
      });
      dispatch(
        setDefaultValue({
          ...response.data.data,
        }),
      );
      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const upsertBusinessField = createAsyncThunk(
  'businessField/upsertBusinessField',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      await apiClient.handleRequest({
        method: data.id ? 'put' : 'post',
        url: `${detailBusinessFieldURI(data.id)}`,
        data: data.data,
      });
      if (data.id) {
        dispatch(setValue({ show: true, body: 'Cập nhật thành công', color: 'success' }));
      } else {
        dispatch(setValue({ show: true, body: 'Tạo mới thành công', color: 'success' }));
      }

      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteBusinessField = createAsyncThunk(
  'service/deleteBusinessField',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      await apiClient
        .handleRequest({
          method: 'delete',
          url: `${detailBusinessFieldURI(data.id)}`,
        })
        .then(res => res.data);
      dispatch(fetchBusinessFields({ page: 1 }));
      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// Then, handle actions in your reducers:
const businessFieldSlice = createSlice({
  name: 'businessField',
  initialState: {
    entities: [],
    loading: 'idle',
    saving: 'idle',
    saving_id: 0,
    currentRequestId: undefined,
    error: null,
    currentPage: 1,
    totalPage: 1,
    total: 0,
  },
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [fetchBusinessFields.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchBusinessFields.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        const { items, page, total, limit } = action.payload;
        state.loading = 'idle';
        state.entities = items;
        state.currentPage = page;
        state.totalPage = Math.ceil(total / limit);
        state.currentRequestId = undefined;
        state.total = total;
      }
    },
    [fetchBusinessFields.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [upsertBusinessField.pending]: (state, action) => {
      if (state.saving === 'idle') {
        state.saving = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    [upsertBusinessField.fulfilled]: (state, action) => {
      state.saving = 'idle';
    },
    [upsertBusinessField.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.saving === 'pending' && state.currentRequestId === requestId) {
        state.saving = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { setItem, resetData } = businessFieldSlice.actions;

export default businessFieldSlice.reducer;
