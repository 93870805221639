/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputCheckbox,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import Status from 'src/enums/Status';
import { fetchBusinessFields } from 'src/slices/BusinessFieldSlice';
import { detailProductCategory, upsertProductCategory } from 'src/slices/ProductCategorySlice';
import { resetData } from 'src/slices/ProductCategoryItemSlice';

const ProductCategoryDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const productCategoryItem = useSelector(state => state.productCategoryItem);
  const businessField = useSelector(state => state.businessField);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const uploadFile = async file => {
    const uploadFormData = new FormData();
    uploadFormData.append('file', file);
    const uploadRes = await apiClient.handleRequest({
      method: 'post',
      url: `${uploadFileURI}`,
      data: uploadFormData,
    });
    return uploadRes.data.data;
  };
  const uploadFiles = async files => {
    if (!files?.length) return [];
    const res = await Promise.all(files.map(item => uploadFile(item)));
    return res;
  };
  const formik = useFormik({
    initialValues: {
      name: productCategoryItem.name || '',
      businessFieldId: productCategoryItem?.businessField?.id
        ? productCategoryItem.businessField.id
        : businessField.entities?.[0]?.id,
      status: productCategoryItem.status || Status.Active.value,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Vui lòng điền tên'),
    }),
    onSubmit: async vals => {
      const data = { ...vals };

      const res = await dispatch(
        upsertProductCategory({
          id: id === 'create' ? '' : id,
          data,
        }),
      );
      if (res.payload === true) {
        history.push(`/product-category`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/product-category`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailProductCategory(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchBusinessFields({ isGetAll: true }));
    })();
  }, [id]);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới danh mục sản phẩm</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name">Tên</CLabel>
                  <CInput
                    id="name"
                    name="name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name || ''}
                  />
                  {touched.name && errors.name ? (
                    <small className="text-danger">{errors.name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="businessFieldId">Danh mục bài viết</CLabel>
                  <CSelect
                    custom
                    name="businessFieldId"
                    id="businessFieldId"
                    value={values.businessFieldId}
                    onChange={async e => {
                      await setData('businessFieldId', +e.target.value);
                      await setData('mediaUrls', []);
                    }}
                  >
                    {businessField.entities.map(item => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </CSelect>
                  {touched.businessFieldId && errors.businessFieldId ? (
                    <small className="text-danger">{errors.businessFieldId}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 1 ? 1 : 0}
                    onChange={e => setData(e.target.name, values.status === 1 ? 2 : 1)}
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default ProductCategoryDetail;
