/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CBadge,
  CPagination,
  CFormGroup,
  CLabel,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CSelect,
  CButton,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { freeSet } from '@coreui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Status from 'src/enums/Status';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteReview, fetchReviews } from 'src/slices/ReviewSlice';
import { setItem } from 'src/slices/ReviewItemSlice';
import { fetchActivities } from 'src/slices/ActivitySlice';

const Reviews = () => {
  const fields = [
    { key: 'id', label: '#' },
    { key: 'image', label: 'Hỉnh ảnh' },
    { key: 'score', label: 'Điểm số' },
    { key: 'status', label: 'Trạng thái' },
    { key: 'actions', label: 'Thao tác' },
  ];

  const review = useSelector(state => state.review);
  const reviewItem = useSelector(state => state.reviewItem);

  const dispatch = useDispatch();
  const history = useHistory();

  const getBadge = status => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const handleDelete = item => {
    dispatch(deleteReview({ ...item }));
  };

  const confirmDelete = item => {
    confirmAlert({
      title: 'Xác nhận xóa đánh giá',
      message: 'Chắc chắn muốn xóa đánh giá này?',
      buttons: [
        {
          label: 'Delete',
          className: 'bg-danger',
          onClick: () => {
            handleDelete(item);
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  const { entities, loading, currentPage, totalPage, total } = review;
  const { status, search } = reviewItem;

  useEffect(() => {
    dispatch(fetchReviews({ page: currentPage, limit: 10 }));
  }, []);

  const handleSearch = () => {
    dispatch(fetchReviews({ search, status, page: 1, limit: 10 }));
  };

  const setFilter = e => {
    const { name, value } = e.target;
    dispatch(setItem({ name, value }));
  };

  const navigateServiceDetail = id => {
    history.push(`reviews/${id}`);
    // dispatch(
    //   NavigationActions.navigate({
    //     routeName: ,
    //   }),
    // );
  };

  return (
    <>
      <CCard>
        <CCardHeader>Danh sách đánh giá</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol class="w-50">
              <CFormGroup>
                <CLabel htmlFor="search">Tìm kiếm</CLabel>
                <CInputGroup>
                  <CInput
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Nhập thông tin muốn tìm kiếm"
                    autoComplete="off"
                    onChange={setFilter}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText className="bg-info text-white">
                      <CIcon content={freeSet.cilSearch} />
                    </CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="status">Trạng thái</CLabel>
                <CSelect custom name="status" id="status" value={null} onChange={setFilter}>
                  <option value="">Tất cả</option>
                  {Object.keys(Status).map(key => (
                    <option value={Status[key].value}>{Status[key].name}</option>
                  ))}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CButton
                className="mb-2 float-right"
                color="info"
                onClick={() => {
                  handleSearch();
                }}
              >
                Search
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <CDataTable
                    items={entities}
                    fields={fields}
                    hover
                    striped
                    bordered
                    size="sm"
                    loading={loading === 'pending'}
                    itemsPerPage={10}
                    activePage={1}
                    scopedSlots={{
                      image: item => (
                        <td>
                          <img src={item.image} alt="" width="80" />
                        </td>
                      ),
                      status: item => (
                        <td>
                          <CBadge color={getBadge(item.status)} role="button">
                            {item.status}
                          </CBadge>
                        </td>
                      ),
                      actions: item => (
                        <td>
                          <span
                            onClick={() => {
                              navigateServiceDetail(item.id);
                            }}
                            className="pointer"
                            role="button"
                          >
                            <CIcon name="cilPencil" className="text-warning" />
                          </span>
                          <span className="ml-2" role="button" onClick={() => confirmDelete(item)}>
                            <CIcon name="cilTrash" className="text-danger" />
                          </span>
                        </td>
                      ),
                    }}
                  />
                  {total > 0 && (
                    <CRow>
                      <CCol>
                        <b>Total: {total}</b>
                      </CCol>
                      <CCol className="text-right">
                        <CPagination
                          activePage={currentPage}
                          pages={totalPage}
                          onActivePageChange={page =>
                            dispatch(fetchActivities({ page, limit: 10 }))
                          }
                          align="end"
                        />
                      </CCol>
                    </CRow>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default Reviews;
