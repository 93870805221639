const Status = {
  Active: {
    value: 1,
    name: 'Kích hoạt',
  },
  InActive: {
    value: 2,
    name: 'Không kích hoạt',
  },
};

export default Status;
