/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputFile,
  CTextarea,
  CInputCheckbox,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import SunEditorCustom from 'src/components/SunEditor';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import {
  detailBusinessField,
  fetchBusinessFields,
  upsertBusinessField,
} from 'src/slices/BusinessFieldSlice';
import { resetData } from 'src/slices/BusinessFieldItemSlice';
import Status from 'src/enums/Status';
import { fetchProductCategory } from 'src/slices/ProductCategorySlice';

const BusinessFieldDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const businessFieldItem = useSelector(state => state.businessFieldItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const businessField = useSelector(state => {
    return state.businessField;
  });

  const uploadFile = async file => {
    const uploadFormData = new FormData();
    uploadFormData.append('file', file);
    const uploadRes = await apiClient.handleRequest({
      method: 'post',
      url: `${uploadFileURI}`,
      data: uploadFormData,
    });
    return uploadRes.data.data;
  };

  const uploadFiles = async files => {
    if (!files?.length) return [];
    const res = await Promise.all(files.map(item => uploadFile(item)));
    return res;
  };
  const formik = useFormik({
    initialValues: {
      parentId: id === 'create' ? 0 : businessFieldItem.parentId,
      name: businessFieldItem.name || '',
      thumbnailUrls: businessFieldItem.thumbnailUrls || [],
      bannerUrl: [businessFieldItem.bannerUrl] || [],
      shortDescription: businessFieldItem.shortDescription || '',
      content: businessFieldItem.content || '',
      status: businessFieldItem.status || Status.Active.value,
      menu: businessFieldItem.menu || '',
      priority: businessFieldItem.priority || 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Vui lòng điền tên'),
      thumbnailUrls: Yup.array().min(1, 'Vui lòng upload thumbnail'),
      bannerUrl: Yup.array().min(1, 'Vui lòng upload banner'),
      shortDescription: Yup.string().required('Vui lòng điền mô tả ngắn'),
      content: Yup.string().required('Vui lòng điền nội dung'),
    }),
    onSubmit: async vals => {
      const data = { ...vals };

      const [resBanner, resThumbnailUrls] = await Promise.all([
        uploadFiles(vals.bannerUrl.filter(item => typeof item !== 'string')),
        uploadFiles(vals.thumbnailUrls.filter(item => typeof item !== 'string')),
      ]);
      const bannerUrls = [...vals.bannerUrl.filter(item => typeof item === 'string'), ...resBanner];
      const [bannerUrl] = bannerUrls;
      data.bannerUrl = bannerUrl;
      data.thumbnailUrls = [
        ...vals.thumbnailUrls.filter(item => typeof item === 'string'),
        ...resThumbnailUrls,
      ];

      const res = await dispatch(
        upsertBusinessField({
          id: id === 'create' ? '' : id,
          data,
        }),
      );
      if (res.payload === true) {
        history.push(`/business-field`);
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/business-field`);
  };

  useEffect(() => {
    dispatch(fetchBusinessFields({ isGetAll: true }));
  }, []);

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailBusinessField(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchProductCategory({ isGetAll: true }));
    })();
  }, [id]);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới lĩnh vực kinh doanh</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="select_menus">Danh mục</CLabel>
                  <CSelect
                    custom
                    name="parentId"
                    id="parentId"
                    value={values.parentId}
                    onChange={async e => {
                      await setData('parentId', +e.target.value);
                    }}
                  >
                    <option value="0">---</option>
                    {businessField.entities.map(item => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="priority">Độ ưu tiên</CLabel>
                  <CInput
                    id="priority"
                    name="priority"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.priority || 0}
                  />
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="bannerUrl">Tải ảnh</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="bannerUrl"
                      onChange={e => {
                        setData('bannerUrl', [e.target.files[0]]);
                      }}
                      accept="image/*"
                    />
                    <CLabel htmlFor="bannerUrl" variant="custom-file">
                      Choose file
                    </CLabel>
                  </CFormGroup>
                  {touched.bannerUrl && errors.bannerUrl ? (
                    <small className="text-danger">{errors.bannerUrl}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name">Tên</CLabel>
                  <CInput
                    id="name"
                    name="name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name || ''}
                  />
                  {touched.name && errors.name ? (
                    <small className="text-danger">{errors.name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                {values.bannerUrl?.[0] && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={
                        typeof values.bannerUrl?.[0] === 'string'
                          ? values.bannerUrl?.[0]
                          : URL.createObjectURL(values.bannerUrl?.[0])
                      }
                      alt=""
                    />
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                {selectedImage && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={URL.createObjectURL(selectedImage)}
                      alt=""
                    />
                  </div>
                )}
                {!selectedImage && businessFieldItem.banner_url && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={businessFieldItem.banner_url}
                      alt=""
                    />
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="shortDescription">Mô tả ngắn</CLabel>
                  <CTextarea
                    id="shortDescription"
                    name="shortDescription"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.shortDescription || ''}
                    rows={4}
                  />
                  {touched.shortDescription && errors.shortDescription ? (
                    <small className="text-danger">{errors.shortDescription}</small>
                  ) : null}
                </CFormGroup>
              </CCol>

              <CCol xs="6">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 1 ? 1 : 0}
                    onChange={e => setData(e.target.name, values.status === 1 ? 2 : 1)}
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="content">Nội dung</CLabel>
                  <SunEditorCustom
                    key={businessFieldItem.content}
                    onChange={value => setData('content', value)}
                    name="content"
                    showToolbar
                    height="100"
                    setContents={businessFieldItem.content}
                  />
                  {touched.content && errors.content ? (
                    <small className="text-danger">{errors.content}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="thumbnailUrls">Upload</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="thumbnailUrls"
                      onChange={e => {
                        setData('thumbnailUrls', [...values.thumbnailUrls, ...e.target.files]);
                      }}
                      accept="image/*"
                      multiple
                    />
                    <CLabel htmlFor="thumbnailUrls" variant="custom-file">
                      Choose file
                    </CLabel>
                  </CFormGroup>
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {(values.thumbnailUrls || []).map((item, index) => {
                    let url = item;
                    if (typeof item !== 'string') url = URL.createObjectURL(item);
                    return (
                      <div
                        style={{ position: 'relative', marginRight: '10px', marginTop: '10px' }}
                        key={index}
                      >
                        <CIcon
                          style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '-10px',
                            cursor: 'pointer',
                          }}
                          name="cil-x-circle"
                          alt="circle"
                          onClick={() =>
                            setData(
                              'thumbnailUrls',
                              values.thumbnailUrls.filter((_, idx) => idx !== index),
                            )
                          }
                        />
                        <img alt="" style={{ width: '100px', height: 'auto' }} src={url} />
                      </div>
                    );
                  })}
                  {touched.thumbnailUrls && errors.thumbnailUrls ? (
                    <small className="text-danger">{errors.thumbnailUrls}</small>
                  ) : null}
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default BusinessFieldDetail;
