/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputFile,
  CInputCheckbox,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import apiClient from 'src/api/api-client';
import { uploadDocURI, uploadFileURI, uploadVideoURI } from 'src/api';
import MediaTypes from 'src/enums/MediaTypes';
import { detailMedia, upsertMedia } from 'src/slices/MediaSlice';
import { resetData } from 'src/slices/MediaItemSlice';
import MediaItemDetail from 'src/components/MediaItemDetail';

const MediaDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const mediaItem = useSelector(state => state.mediaItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const uploadFile = async (file, type) => {
    const uploadFormData = new FormData();
    uploadFormData.append('file', file);
    const uploadRes = await apiClient.handleRequest({
      method: 'post',
      url: `${type === 'video' ? uploadVideoURI : type === 'file' ? uploadDocURI : uploadFileURI}`,
      data: uploadFormData,
    });
    return uploadRes.data.data;
  };
  const uploadFiles = async (files, type) => {
    if (!files?.length) return [];
    const res = await Promise.all(files.map(item => uploadFile(item, type)));
    return res;
  };

  const formik = useFormik({
    initialValues: {
      mediaType: mediaItem.mediaType || MediaTypes.IMAGE.value,
      mediaItems: mediaItem.mediaItems ? [...mediaItem.mediaItems] : [],
      thumbnailUrl: mediaItem.thumbnailUrl ? [mediaItem.thumbnailUrl] : [],
      title: mediaItem.title || '',
      status: mediaItem.status || 1,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mediaType: Yup.string().required('Vui lòng chọn loại tài liệu'),
      thumbnailUrl: Yup.array().min(1, 'Vui lòng upload thumbnail'),
      title: Yup.string().required('Vui lòng điền tiêu đề'),
    }),
    onSubmit: async vals => {
      const data = { ...vals };
      const [resMediaUrls, resThumbnailUrl] = await Promise.all([
        uploadFiles(
          vals.mediaItems.filter(item => typeof item.url !== 'string').map(item => item.url),
          vals.mediaType === 1 ? 'video' : vals.mediaType === 2 ? 'file' : null,
        ),
        uploadFiles(
          vals.mediaType !== 1 ? vals.thumbnailUrl.filter(item => typeof item !== 'string') : null,
        ),
      ]);
      data.mediaItems = [
        ...vals.mediaItems.filter(item => typeof item.url === 'string'),
        ...vals.mediaItems
          .filter(item => typeof item.url !== 'string')
          .map((item, index) => ({ ...item, url: resMediaUrls[index] })),
      ];
      const thumbnailUrls = [
        ...vals.thumbnailUrl.filter(item => typeof item === 'string'),
        ...resThumbnailUrl,
      ];
      const [thumbnailUrl] = thumbnailUrls;
      data.thumbnailUrl = thumbnailUrl;

      const res = await dispatch(
        upsertMedia({
          id: id === 'create' ? '' : id,
          data,
        }),
      );
      if (res.payload === true) {
        history.push(`/media`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;

  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    return setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/media`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailMedia(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  useEffect(() => {
    return async () => {
      await dispatch(resetData());
    };
  }, []);

  const isEdit = id !== 'create';
  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới thư viện</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="mediaType">Loại tài liệu</CLabel>
                  <CSelect
                    custom
                    name="mediaType"
                    id="mediaType"
                    value={values.mediaType}
                    disabled={isEdit}
                    onChange={
                      isEdit
                        ? null
                        : async e => {
                            await setData('mediaType', +e.target.value);
                            await setData('mediaItems', []);
                          }
                    }
                  >
                    {Object.values(MediaTypes).map(item => {
                      return (
                        <option value={item.value} key={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </CSelect>
                  {touched.mediaType && errors.mediaType ? (
                    <small className="text-danger">{errors.mediaType}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title">Tiêu đề</CLabel>
                  <CInput
                    id="title"
                    name="title"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title || ''}
                  />
                  {touched.title && errors.title ? (
                    <small className="text-danger">{errors.title}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            {values.mediaType !== 1 ? (
              <CRow>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="thumbnailUrl">Upload Thumbnail</CLabel>
                    <CFormGroup variant="custom-checkbox">
                      <CInputFile
                        custom
                        id="thumbnailUrl"
                        onChange={e => {
                          setData('thumbnailUrl', [e.target.files[0]]);
                        }}
                        accept="image/*"
                        multiple
                      />
                      <CLabel htmlFor="thumbnailUrl" variant="custom-file">
                        Choose file
                      </CLabel>
                    </CFormGroup>
                  </CFormGroup>
                </CCol>
                <CCol xs="12">
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {(values.thumbnailUrl || []).map((item, index) => {
                      let url = item;
                      if (typeof item !== 'string') url = URL.createObjectURL(item);
                      return (
                        <div
                          style={{ position: 'relative', marginRight: '10px', marginTop: '10px' }}
                          key={index}
                        >
                          <CIcon
                            style={{
                              position: 'absolute',
                              top: '-8px',
                              right: '-10px',
                              cursor: 'pointer',
                            }}
                            name="cil-x-circle"
                            alt="circle"
                            onClick={() =>
                              setData(
                                'thumbnailUrl',
                                values.thumbnailUrl.filter((_, idx) => idx !== index),
                              )
                            }
                          />
                          <img alt="" style={{ width: '100px', height: 'auto' }} src={url} />
                        </div>
                      );
                    })}
                    {touched.thumbnailUrl && errors.thumbnailUrl ? (
                      <small className="text-danger">{errors.thumbnailUrl}</small>
                    ) : null}
                  </div>
                </CCol>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="mediaItems">Upload</CLabel>
                    <CFormGroup variant="custom-checkbox">
                      <CInputFile
                        custom
                        id="mediaItems"
                        onChange={e => {
                          setData(
                            'mediaItems',
                            values.mediaType === 0
                              ? [
                                  ...values.mediaItems,
                                  ...[...e.target.files].map(item => ({ url: item, title: '' })),
                                ]
                              : [...e.target.files].map(item => ({ url: item, title: '' })),
                          );
                        }}
                        accept={
                          // eslint-disable-next-line no-nested-ternary
                          values.mediaType === 0
                            ? 'image/*'
                            : values.mediaType === 1
                            ? 'video/mp4,video/x-m4v,video/*'
                            : 'application/pdf'
                        }
                        multiple={values.mediaType === 0}
                      />
                      <CLabel htmlFor="mediaItems" variant="custom-file">
                        Choose file
                      </CLabel>
                    </CFormGroup>
                  </CFormGroup>
                </CCol>
              </CRow>
            ) : (
              <CRow>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="title">Video url</CLabel>
                    <CInput
                      id="thumbnailUrl"
                      name="thumbnailUrl"
                      onChange={e => setData(e.target.name, [e.target.value])}
                      value={values.thumbnailUrl || ''}
                    />
                    {touched.thumbnailUrl && errors.thumbnailUrl ? (
                      <small className="text-danger">{errors.thumbnailUrl}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
              </CRow>
            )}
            <CRow>
              <CCol xs="12">
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {(values.mediaItems || []).map((item, index) => {
                    return (
                      <MediaItemDetail
                        key={index}
                        data={item}
                        onDelete={() =>
                          setData(
                            'mediaItems',
                            values.mediaItems.filter((_, idx) => idx !== index),
                          )
                        }
                        onChange={value =>
                          setData(
                            'mediaItems',
                            values.mediaItems.map((media, idx) => {
                              if (idx === index) {
                                return { ...media, title: value };
                              }
                              return media;
                            }),
                          )
                        }
                        mediaType={values.mediaType}
                      />
                    );
                  })}
                  {touched.mediaItems && errors.mediaItems ? (
                    <small className="text-danger">{errors.mediaItems}</small>
                  ) : null}
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="6">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status}
                    onChange={e => setData(e.target.name, values.status === 1 ? 0 : 1)}
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default MediaDetail;
