/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { detailRegistration, upsertRegistration } from 'src/slices/RegistrationSlice';
import { resetData } from 'src/slices/RegistrationItemSlice';

const RegistrationDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const item = useSelector(state => state.registrationItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const formik = useFormik({
    initialValues: {
      parent_name: item.parent_name || '',
      parent_email: item.parent_email || '',
      parent_phone: item.parent_phone || '',
      address: item.address || '',
      child_name: item.child_name || '',
      child_birthday: item.child_birthday || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      parent_name: Yup.string().required('Vui lòng điền họ tên'),
      parent_email: Yup.string().required('Vui lòng điền email'),
      parent_phone: Yup.string().required('Vui lòng điền số điện thoại'),
      address: Yup.string().required('Vui lòng điền địa chỉ'),
      child_name: Yup.string().required('Vui lòng điền tên của bé'),
      child_birthday: Yup.number().required('Vui lòng điền năm sinh của bé'),
    }),
    onSubmit: async vals => {
      const res = await dispatch(
        upsertRegistration({ id: id === 'create' ? '' : id, data: { ...vals } }),
      );
      if (res.payload === true) {
        history.push(`/registrations`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/registrations`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailRegistration(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Đăng ký khóa học</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="parent_name">Tên phụ huynh</CLabel>
                  <CInput
                    id="parent_name"
                    name="parent_name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.parent_name || ''}
                  />
                  {touched.parent_name && errors.parent_name ? (
                    <small className="text-danger">{errors.parent_name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="parent_email">Địa chỉ Email</CLabel>
                  <CInput
                    id="parent_email"
                    name="parent_email"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.parent_email || ''}
                  />
                  {touched.parent_email && errors.parent_email ? (
                    <small className="text-danger">{errors.parent_email}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="parent_phone">Số điện thoại phụ huynh</CLabel>
                  <CInput
                    id="parent_phone"
                    name="parent_phone"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.parent_phone || ''}
                  />
                  {touched.parent_phone && errors.parent_phone ? (
                    <small className="text-danger">{errors.parent_phone}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="address">Địa chỉ</CLabel>
                  <CInput
                    id="address"
                    name="address"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.address || ''}
                  />
                  {touched.address && errors.address ? (
                    <small className="text-danger">{errors.address}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="child_name">Họ tên bé</CLabel>
                  <CInput
                    id="child_name"
                    name="child_name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.child_name || ''}
                  />
                  {touched.child_name && errors.child_name ? (
                    <small className="text-danger">{errors.child_name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="child_birthday">Năm sinh</CLabel>
                  <CInput
                    type="number"
                    id="child_birthday"
                    name="child_birthday"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.child_birthday || ''}
                  />
                  {touched.child_birthday && errors.child_birthday ? (
                    <small className="text-danger">{errors.child_birthday}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default RegistrationDetail;
