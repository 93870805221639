/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputFile,
  CTextarea,
  CInputCheckbox,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SunEditorCustom from 'src/components/SunEditor';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import Status from 'src/enums/Status';
import { detailProduct, upsertProduct } from 'src/slices/ProductSlice';
import { resetData } from 'src/slices/ProductItemSlice';
import { fetchProductCategory } from 'src/slices/ProductCategorySlice';

const defaultCharacteristic = {
  bannerUrls: [],
  title: '',
  name: '',
  content: '',
};

const ProductDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const productItem = useSelector(state => state.productItem);
  const productCategory = useSelector(state => state.productCategory);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const uploadFile = async file => {
    const uploadFormData = new FormData();
    uploadFormData.append('file', file);
    const uploadRes = await apiClient.handleRequest({
      method: 'post',
      url: `${uploadFileURI}`,
      data: uploadFormData,
    });
    return uploadRes.data.data;
  };
  const uploadFiles = async files => {
    if (!files?.length) return [];
    const res = await Promise.all(files.map(item => uploadFile(item)));
    return res;
  };
  const formik = useFormik({
    initialValues: {
      title: productItem.title || '',
      bannerUrls: productItem.bannerUrls || [],
      thumbnailUrl: productItem.thumbnailUrl ? [productItem.thumbnailUrl] : [],
      characteristics: productItem.characteristics || [],
      shortDescription: productItem.shortDescription || '',
      content: productItem.content || '',
      projectName: productItem.projectName || '',
      location: productItem.location || '',
      owner: productItem.owner || '',
      architectureFirm: productItem.architectureFirm || '',
      landArea: productItem.landArea || '',
      constructionArea: productItem.constructionArea || '',
      blockNumber: productItem.blockNumber || '',
      productNumber: productItem.productNumber || '',
      ownershipPeriod: productItem.ownershipPeriod || '',
      completionTime: productItem.completionTime || '',
      visitUrl: productItem.visitUrl || '',
      statement: productItem.statement || '',
      status: productItem.status || Status.Active.value,
      categoryId: productItem.categoryId || productCategory.entities?.[0]?.id,
      showOnHomePage: productItem.showOnHomePage || false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required('Vui lòng điền tiêu đề'),
      bannerUrls: Yup.array().min(1, 'Vui lòng upload banner'),
      characteristics: Yup.array().of(
        Yup.object().shape({
          bannerUrls: Yup.array().min(1, 'Vui lòng upload banner'),
          name: Yup.string().required('Vui lòng điền tên'),
          title: Yup.string().required('Vui lòng điền tiêu đề'),
          content: Yup.string().required('Vui lòng điền nội dung'),
        }),
      ),
      thumbnailUrl: Yup.array().min(1, 'Vui lòng upload thumbnail'),
      shortDescription: Yup.string().required('Vui lòng điền mô tả ngắn'),
      content: Yup.string().required('Vui lòng điền nội dung'),
      // projectName: Yup.string().required('Vui lòng điền tên dự án'),
      // location: Yup.string().required('Vui lòng điền vị trí'),
      // owner: Yup.string().required('Vui lòng điền chủ đầu tư'),
      // architectureFirm: Yup.string().required('Vui lòng điền kiến trúc'),
      // landArea: Yup.number().required('Vui lòng điền diện tích'),
      // constructionArea: Yup.number().required('Vui lòng điền diện tích thi công'),
      // blockNumber: Yup.string().required('Vui lòng điền số tòa nhà'),
      // productNumber: Yup.string().required('Vui lòng điền số sản phẩm'),
      // ownershipPeriod: Yup.string().required('Vui lòng điền thời hạn sở hữu'),
      // completionTime: Yup.string().required('Vui lòng điền thời gian hoàn thành'),
      // visitUrl: Yup.string().required('Vui lòng điền đường dẫn website'),
      // statement: Yup.string().required('Vui lòng điền mô tả trang chủ'),
    }),
    onSubmit: async vals => {
      const data = { ...vals };

      const [resThumbnail, resBannerUrls, resCharacteristics] = await Promise.all([
        uploadFiles(vals.thumbnailUrl.filter(item => typeof item !== 'string')),
        uploadFiles(vals.bannerUrls.filter(item => typeof item !== 'string')),
        Promise.all(
          vals.characteristics.map(characteristic => {
            return uploadFiles(characteristic.bannerUrls.filter(item => typeof item !== 'string'));
          }),
        ),
      ]);
      const thumbnailUrls = [
        ...vals.thumbnailUrl.filter(item => typeof item === 'string'),
        ...resThumbnail,
      ];
      const [thumbnailUrl] = thumbnailUrls;
      data.thumbnailUrl = thumbnailUrl;
      data.bannerUrls = [
        ...vals.bannerUrls.filter(item => typeof item === 'string'),
        ...resBannerUrls,
      ];
      resCharacteristics.forEach((banners, index) => {
        if (banners.length)
          data.characteristics[index] = {
            ...data.characteristics[index],
            bannerUrls: [
              ...data.characteristics[index].bannerUrls.filter(item => typeof item === 'string'),
              ...banners,
            ],
          };
      });

      const res = await dispatch(
        upsertProduct({
          id: id === 'create' ? '' : id,
          data,
        }),
      );
      if (res.payload === true) {
        history.push(`/product`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/product`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailProduct(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchProductCategory({ isGetAll: true }));
    })();
  }, [id]);

  return (
    <>
      <CForm
        action=""
        method="product"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới sản phẩm</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="thumbnailUrl">Tải ảnh</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="thumbnailUrl"
                      onChange={e => {
                        setData('thumbnailUrl', [e.target.files[0]]);
                      }}
                      accept="image/*"
                    />
                    <CLabel htmlFor="thumbnailUrl" variant="custom-file">
                      Choose file
                    </CLabel>
                  </CFormGroup>
                  {touched.thumbnailUrl && errors.thumbnailUrl ? (
                    <small className="text-danger">{errors.thumbnailUrl}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="categoryId">Danh mục sản phẩm</CLabel>
                  <CSelect
                    custom
                    name="categoryId"
                    id="categoryId"
                    value={values.categoryId}
                    onChange={async e => {
                      await setData('categoryId', +e.target.value);
                    }}
                  >
                    {productCategory.entities.map(item => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </CSelect>
                  {touched.categoryId && errors.categoryId ? (
                    <small className="text-danger">{errors.categoryId}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                {values.thumbnailUrl?.[0] && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={
                        typeof values.thumbnailUrl?.[0] === 'string'
                          ? values.thumbnailUrl?.[0]
                          : URL.createObjectURL(values.thumbnailUrl?.[0])
                      }
                      alt=""
                    />
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="bannerUrls">Upload banner</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="bannerUrls"
                      onChange={e => {
                        setData('bannerUrls', [...values.bannerUrls, ...e.target.files]);
                      }}
                      accept="image/*"
                      multiple
                    />
                    <CLabel htmlFor="bannerUrls" variant="custom-file">
                      Choose file
                    </CLabel>
                  </CFormGroup>
                  {touched.bannerUrls && errors.bannerUrls ? (
                    <small className="text-danger">{errors.bannerUrls}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {(values.bannerUrls || []).map((item, index) => {
                    let url = item;
                    if (typeof item !== 'string') url = URL.createObjectURL(item);
                    return (
                      <div
                        style={{ position: 'relative', marginRight: '10px', marginTop: '10px' }}
                        key={index}
                      >
                        <CIcon
                          style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '-10px',
                            cursor: 'pointer',
                          }}
                          name="cil-x-circle"
                          alt="circle"
                          onClick={() =>
                            setData(
                              'bannerUrls',
                              values.bannerUrls.filter((_, idx) => idx !== index),
                            )
                          }
                        />
                        <img alt="" style={{ width: '100px', height: 'auto' }} src={url} />
                      </div>
                    );
                  })}
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title">Tiêu đề</CLabel>
                  <CInput
                    id="title"
                    name="title"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title || ''}
                  />
                  {touched.title && errors.title ? (
                    <small className="text-danger">{errors.title}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="shortDescription">Mô tả ngắn</CLabel>
                  <CTextarea
                    id="shortDescription"
                    name="shortDescription"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.shortDescription || ''}
                    rows={4}
                  />
                  {touched.shortDescription && errors.shortDescription ? (
                    <small className="text-danger">{errors.shortDescription}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="content">Nội dung</CLabel>
                  <SunEditorCustom
                    key={productItem.content}
                    onChange={value => setData('content', value)}
                    name="content"
                    showToolbar
                    height="100"
                    setContents={productItem.content}
                  />
                  {touched.content && errors.content ? (
                    <small className="text-danger">{errors.content}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="projectName">Tên dự án</CLabel>
                  <CInput
                    id="projectName"
                    name="projectName"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.projectName || ''}
                  />
                  {touched.projectName && errors.projectName ? (
                    <small className="text-danger">{errors.projectName}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="location">Vị trí</CLabel>
                  <CTextarea
                    id="location"
                    name="location"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.location || ''}
                    rows={4}
                  />
                  {touched.location && errors.location ? (
                    <small className="text-danger">{errors.location}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="visitUrl">Website</CLabel>
                  <CInput
                    id="visitUrl"
                    name="visitUrl"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.visitUrl || ''}
                  />
                  {touched.visitUrl && errors.visitUrl ? (
                    <small className="text-danger">{errors.visitUrl}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="statement">Mô tả trang chủ</CLabel>
                  <CInput
                    id="statement"
                    name="statement"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.statement || ''}
                  />
                  {touched.statement && errors.statement ? (
                    <small className="text-danger">{errors.statement}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="owner">Chủ đầu tư</CLabel>
                  <CInput
                    id="owner"
                    name="owner"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.owner || ''}
                  />
                  {touched.owner && errors.owner ? (
                    <small className="text-danger">{errors.owner}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="architectureFirm">Đơn Vị Thiết Kế</CLabel>
                  <CInput
                    id="architectureFirm"
                    name="architectureFirm"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.architectureFirm || ''}
                  />
                  {touched.architectureFirm && errors.architectureFirm ? (
                    <small className="text-danger">{errors.architectureFirm}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="landArea">Diện Tích Đất</CLabel>
                  <CInput
                    id="landArea"
                    name="landArea"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.landArea || ''}
                    type="number"
                  />
                  {touched.landArea && errors.landArea ? (
                    <small className="text-danger">{errors.landArea}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="constructionArea">Diện Tích Sàn Xây Dựng</CLabel>
                  <CInput
                    id="constructionArea"
                    name="constructionArea"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.constructionArea || ''}
                    type="number"
                  />
                  {touched.constructionArea && errors.constructionArea ? (
                    <small className="text-danger">{errors.constructionArea}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="blockNumber">Số Block</CLabel>
                  <CInput
                    id="blockNumber"
                    name="blockNumber"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.blockNumber || ''}
                  />
                  {touched.blockNumber && errors.blockNumber ? (
                    <small className="text-danger">{errors.blockNumber}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="productNumber">Số Sản Phẩm</CLabel>
                  <CInput
                    id="productNumber"
                    name="productNumber"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.productNumber || ''}
                  />
                  {touched.productNumber && errors.productNumber ? (
                    <small className="text-danger">{errors.productNumber}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="ownershipPeriod">Thời Hạn Sở Hữu</CLabel>
                  <CInput
                    id="ownershipPeriod"
                    name="ownershipPeriod"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.ownershipPeriod || ''}
                  />
                  {touched.ownershipPeriod && errors.ownershipPeriod ? (
                    <small className="text-danger">{errors.ownershipPeriod}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="completionTime">Hoàn tất dự kiến</CLabel>
                  <CInput
                    id="completionTime"
                    name="completionTime"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.completionTime || ''}
                  />
                  {touched.completionTime && errors.completionTime ? (
                    <small className="text-danger">{errors.completionTime}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 1 ? 1 : 0}
                    onChange={e => setData(e.target.name, values.status === 1 ? 2 : 1)}
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="showOnHomePage"
                    name="showOnHomePage"
                    checked={values.showOnHomePage}
                    onChange={e => setData(e.target.name, !values.showOnHomePage)}
                  />
                  <CLabel htmlFor="showOnHomePage">Trang chủ</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CButton
                  color="primary"
                  style={{ marginRight: 10 }}
                  type="button"
                  onClick={() =>
                    setData('characteristics', [...values.characteristics, defaultCharacteristic])
                  }
                >
                  Thêm chi tiết
                </CButton>
              </CCol>
            </CRow>
            {values.characteristics.map((item, index) => {
              const data = [...values.characteristics];
              return (
                <div
                  style={{
                    position: 'relative',
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    marginTop: '10px',
                    background: '#c6c6c6',
                    borderRadius: '10px',
                  }}
                  key={index}
                >
                  <CIcon
                    style={{
                      position: 'absolute',
                      top: '-8px',
                      right: '-10px',
                      cursor: 'pointer',
                    }}
                    name="cil-x-circle"
                    alt="circle"
                    onClick={() =>
                      setData(
                        'characteristics',
                        values.characteristics.filter((_, idx) => idx !== index),
                      )
                    }
                  />
                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor={'_title' + `_${index}`}>Tiêu đề</CLabel>
                        <CInput
                          id={'_title' + `_${index}`}
                          name={'_title' + `_${index}`}
                          onChange={e => {
                            data[index] = { ...data[index], title: e.target.value };
                            setData('characteristics', data);
                          }}
                          value={item.title || ''}
                        />
                        {touched.characteristics?.[index]?.title &&
                        errors.characteristics?.[index]?.title ? (
                          <small className="text-danger">
                            {errors.characteristics?.[index]?.title}
                          </small>
                        ) : null}
                      </CFormGroup>
                    </CCol>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor={'_name' + `_${index}`}>Tên</CLabel>
                        <CInput
                          id={'_name' + `_${index}`}
                          name={'_name' + `_${index}`}
                          onChange={e => {
                            data[index] = { ...data[index], name: e.target.value };
                            setData('characteristics', data);
                          }}
                          value={item.name || ''}
                        />
                        {touched.characteristics?.[index]?.name &&
                        errors.characteristics?.[index]?.name ? (
                          <small className="text-danger">
                            {errors.characteristics?.[index]?.name}
                          </small>
                        ) : null}
                      </CFormGroup>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor={'_bannerUrls' + `_${index}`}>Upload banner</CLabel>
                        <CFormGroup variant="custom-checkbox">
                          <CInputFile
                            custom
                            id={'_bannerUrls' + `_${index}`}
                            onChange={e => {
                              data[index] = {
                                ...data[index],
                                bannerUrls: [...item.bannerUrls, ...e.target.files],
                              };
                              setData('characteristics', data);
                            }}
                            onClick={event => {
                              event.target.value = '';
                            }}
                            accept="image/*"
                            multiple
                          />
                          <CLabel htmlFor={'_bannerUrls' + `_${index}`} variant="custom-file">
                            Choose file
                          </CLabel>
                        </CFormGroup>
                        {touched.characteristics?.[index]?.bannerUrls &&
                        errors.characteristics?.[index]?.bannerUrls ? (
                          <small className="text-danger">
                            {errors.characteristics?.[index]?.bannerUrls}
                          </small>
                        ) : null}
                      </CFormGroup>
                    </CCol>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor={'content' + `_${index}`}>nội dung</CLabel>
                        <CTextarea
                          id={'content' + `_${index}`}
                          name={'content' + `_${index}`}
                          onChange={e => {
                            data[index] = { ...data[index], content: e.target.value };
                            setData('characteristics', data);
                          }}
                          value={item.content || ''}
                          rows={4}
                        />
                        {touched.characteristics?.[index]?.content &&
                        errors.characteristics?.[index]?.content ? (
                          <small className="text-danger">
                            {errors.characteristics?.[index]?.content}
                          </small>
                        ) : null}
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs="12">
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {(item.bannerUrls || []).map((banner, bannerIndex) => {
                          let url = banner;
                          if (typeof banner !== 'string') url = URL.createObjectURL(banner);
                          return (
                            <div
                              style={{
                                position: 'relative',
                                marginRight: '10px',
                                marginTop: '10px',
                              }}
                              key={index}
                            >
                              <CIcon
                                style={{
                                  position: 'absolute',
                                  top: '-8px',
                                  right: '-10px',
                                  cursor: 'pointer',
                                }}
                                name="cil-x-circle"
                                alt="circle"
                                onClick={e => {
                                  data[index] = {
                                    ...data[index],
                                    bannerUrls: item.bannerUrls.filter(
                                      (_, idx) => idx !== bannerIndex,
                                    ),
                                  };
                                  setData('characteristics', data);
                                }}
                              />
                              <img alt="" style={{ width: '100px', height: 'auto' }} src={url} />
                            </div>
                          );
                        })}
                      </div>
                    </CCol>
                  </CRow>
                </div>
              );
            })}
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default ProductDetail;
