import constants from 'src/constants';
import { CookieService } from 'src/libs/cookie.service';

const domain = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

export const checkDomain = () => {
  if (CookieService.get(constants.LANGUAGE) === 'en') return process.env.REACT_APP_API_BASE_URL_EN;
  return domain;
};

export const defaultUrl = `${checkDomain()}/api/v1`;
export const getAuthURI = `${checkDomain()}/api/account/authenticate`;
export const getJobURI = `${defaultUrl}/jobs`;
export const upsertJobURI = `${defaultUrl}/jobs`;
export const detailJobURI = id => `${defaultUrl}/jobs/${id}`;
export const updateJobStatusURI = id => `${defaultUrl}/jobs/update-status/${id}`;
export const getJobCategoryURI = `${defaultUrl}/categories`;
export const createJobCategoryURI = `${defaultUrl}/categories`;
export const getCVURI = `${defaultUrl}/cv`;
export const viewCVURI = path => `${defaultUrl}${path}`;
export const updateCVStatusURI = id => `${defaultUrl}/cv/update-status/${id}`;
export const getContactURI = `${defaultUrl}/contacts`;
export const updateContactStatusURI = id => `${defaultUrl}/contacts/update-status/${id}`;

export const getBlockURI = `${defaultUrl}/blocks`;
export const getBodURI = `${defaultUrl}/bods`;
export const upsertBodURI = `${defaultUrl}/bods`;
export const detailBodURI = id => `${defaultUrl}/bods/${id}`;
export const updateBodStatusURI = id => `${defaultUrl}/bods/update-status/${id}`;
export const viewAvatar = path => `${defaultUrl}${path}`;
export const searchBodsURI = `${defaultUrl}/bods/searchBOD`;

export const getIntroductionURI = `${defaultUrl}/introductions`;
export const upsertIntroductionURI = `${defaultUrl}/introductions`;
export const updateIntroductionStatusURI = id => `${defaultUrl}/introductions/${id}`;
export const detailIntroductionURI = id => `${defaultUrl}/introductions/getIntroductionAdmin/${id}`;
export const updateStatusIntroductionStatusURI = id =>
  `${defaultUrl}/introductions/update-status/${id}`;
export const viewImage = path => `${defaultUrl}${path}`;
export const searchIntroductionsURI = `${defaultUrl}/introductions/searchIntroduction`;

export const getNewURI = `${defaultUrl}/news`;
export const upsertNewURI = `${defaultUrl}/news`;
export const updateNewStatusURI = id => `${defaultUrl}/news/${id}`;
export const viewBanner = path => `${defaultUrl}${path}`;
export const searchNewsURI = `${defaultUrl}/news/searchNews`;

export const serviceURI = `${defaultUrl}/services`;
export const detailServiceURI = id => `${defaultUrl}/services/${id}`;
export const updateServiceStatusURI = id => `${defaultUrl}/services/update-status/${id}`;
export const viewServiceURI = path => `${defaultUrl}${path}`;

export const reviewURI = `${defaultUrl}/reviews`;
export const detailReviewURI = id => `${defaultUrl}/reviews/${id}`;

export const activityURI = `${defaultUrl}/activity_images`;
export const detailActivityURI = id => `${defaultUrl}/activity_images/${id}`;

export const registrationURI = `${defaultUrl}/registrations`;
export const detailRegistrationURI = id => `${defaultUrl}/registrations/${id}`;

export const newsURI = `${defaultUrl}/post`;
export const detailNewsURI = id => `${defaultUrl}/post/${id}`;

export const productURI = `${defaultUrl}/product`;
export const detailProductURI = id => `${defaultUrl}/product/${id}`;

export const businessFieldURI = `${defaultUrl}/business-field`;
export const detailBusinessFieldURI = id => `${defaultUrl}/business-field/${id}`;

export const postCategoryURI = `${defaultUrl}/post-category`;
export const detailPostCategoryURI = id => `${defaultUrl}/post-category/${id}`;

export const productCategoryURI = `${defaultUrl}/product-category`;
export const detailProductCategoryURI = id => `${defaultUrl}/product-category/${id}`;

export const mediaURI = `${defaultUrl}/media`;
export const detailMediaURI = id => `${defaultUrl}/media/${id}`;

export const contactURI = `${defaultUrl}/contact`;
export const detailContactURI = id => `${defaultUrl}/contact/${id}`;

export const candidateURI = `${defaultUrl}/candidates`;

export const getConfigURI = `${defaultUrl}/setting`;
export const uploadBannerURI = `${defaultUrl}/configs/uploadBanner`;
export const uploadFileURI = `${defaultUrl}/upload/image`;
export const uploadVideoURI = `${defaultUrl}/upload/video`;
export const uploadDocURI = `${defaultUrl}/upload/file`;
export const uploadListFileURI = `${defaultUrl}/uploadListFiles`;
export const getProcessURI = `${defaultUrl}/configs/getProcess`;

export const serviceCategoryURI = `${defaultUrl}/services_categories`;

export const getListIotProjectURI = `${defaultUrl}/getListIotProject`;
export const upsertIotProjectURI = `${defaultUrl}/storeIotProject`;

export const getCustomerCategoryURI = `${defaultUrl}/customer-categories`;
