/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputFile,
  CTextarea,
  CInputCheckbox,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import SunEditorCustom from 'src/components/SunEditor';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import Status from 'src/enums/Status';
import { detailNews, upsertNews } from 'src/slices/NewsSlice';
import { resetData } from 'src/slices/NewsItemSlice';
import { fetchPostCategory } from 'src/slices/PostCategorySlice';
import DatePicker from 'src/components/DatePicker';

const PostDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const postItem = useSelector(state => state.newsItem);
  const postCategory = useSelector(state => state.postCategory);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const uploadFile = async file => {
    const uploadFormData = new FormData();
    uploadFormData.append('file', file);
    const uploadRes = await apiClient.handleRequest({
      method: 'post',
      url: `${uploadFileURI}`,
      data: uploadFormData,
    });
    return uploadRes.data.data;
  };
  const uploadFiles = async files => {
    if (!files?.length) return [];
    const res = await Promise.all(files.map(item => uploadFile(item)));
    return res;
  };
  const formik = useFormik({
    initialValues: {
      title: postItem.title || '',
      thumbnailUrl: [postItem.thumbnailUrl] || [],
      shortDescription: postItem.shortDescription || '',
      content: postItem.content || '',
      status: postItem.status || Status.Active.value,
      categoryId: postItem.categoryId || postCategory.entities?.[0]?.id,
      showOnHomePage: postItem.showOnHomePage || false,
      publishedDate: postItem.publishedDate ? new Date(postItem.publishedDate) : '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required('Vui lòng điền tiêu đề'),
      thumbnailUrl: Yup.array().min(1, 'Vui lòng upload thumbnail'),
      shortDescription: Yup.string().required('Vui lòng điền mô tả ngắn'),
      content: Yup.string().required('Vui lòng điền nội dung'),
      publishedDate: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
    onSubmit: async vals => {
      const data = { ...vals };

      const [resThumbnail] = await Promise.all([
        uploadFiles(vals.thumbnailUrl.filter(item => typeof item !== 'string')),
      ]);
      const thumbnailUrls = [
        ...vals.thumbnailUrl.filter(item => typeof item === 'string'),
        ...resThumbnail,
      ];
      const [thumbnailUrl] = thumbnailUrls;
      data.thumbnailUrl = thumbnailUrl;

      const res = await dispatch(
        upsertNews({
          id: id === 'create' ? '' : id,
          data,
        }),
      );
      if (res.payload === true) {
        history.push(`/post`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/post`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailNews(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchPostCategory({ isGetAll: true }));
    })();
  }, [id]);

  console.log(formik);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới bài viết</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="thumbnailUrl">Tải ảnh</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="thumbnailUrl"
                      onChange={e => {
                        setData('thumbnailUrl', [e.target.files[0]]);
                      }}
                      accept="image/*"
                    />
                    <CLabel htmlFor="thumbnailUrl" variant="custom-file">
                      Choose file
                    </CLabel>
                  </CFormGroup>
                  {touched.thumbnailUrl && errors.thumbnailUrl ? (
                    <small className="text-danger">{errors.thumbnailUrl}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="categoryId">Danh mục bài viết</CLabel>
                  <CSelect
                    custom
                    name="categoryId"
                    id="categoryId"
                    value={values.categoryId}
                    onChange={async e => {
                      await setData('categoryId', +e.target.value);
                      await setData('mediaUrls', []);
                    }}
                  >
                    {postCategory.entities.map(item => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </CSelect>
                  {touched.categoryId && errors.categoryId ? (
                    <small className="text-danger">{errors.categoryId}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                {values.thumbnailUrl?.[0] && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={
                        typeof values.thumbnailUrl?.[0] === 'string'
                          ? values.thumbnailUrl?.[0]
                          : URL.createObjectURL(values.thumbnailUrl?.[0])
                      }
                      alt=""
                    />
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title">Tiêu đề</CLabel>
                  <CInput
                    id="title"
                    name="title"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title || ''}
                  />
                  {touched.title && errors.title ? (
                    <small className="text-danger">{errors.title}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="publishedDate">Ngày</CLabel>
                  <DatePicker formik={formik} id="publishedDate" name="publishedDate" />
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="shortDescription">Mô tả ngắn</CLabel>
                  <CTextarea
                    id="shortDescription"
                    name="shortDescription"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.shortDescription || ''}
                    rows={4}
                  />
                  {touched.shortDescription && errors.shortDescription ? (
                    <small className="text-danger">{errors.shortDescription}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="content">Nội dung</CLabel>
                  <SunEditorCustom
                    key={postItem.content}
                    onChange={value => setData('content', value)}
                    name="content"
                    showToolbar
                    height="100"
                    setContents={postItem.content}
                  />
                  {touched.content && errors.content ? (
                    <small className="text-danger">{errors.content}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 1 ? 1 : 0}
                    onChange={e => setData(e.target.name, values.status === 1 ? 2 : 1)}
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="showOnHomePage"
                    name="showOnHomePage"
                    checked={values.showOnHomePage}
                    onChange={e => setData(e.target.name, !values.showOnHomePage)}
                  />
                  <CLabel htmlFor="showOnHomePage">Trang chủ</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default PostDetail;
