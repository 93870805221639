/* eslint-disable radix */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { detailProductURI, productURI } from 'src/api';
import apiClient from 'src/api/api-client';
import { setValue } from './MessageSlice';
import { setDefaultValue } from './ProductItemSlice';

// First, create the thunk
export const fetchProducts = createAsyncThunk(
  'product/fetchProducts',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiClient
        .handleRequest({
          method: 'get',
          url: `${productURI}`,
          params: { ...params, show_all: true },
        })
        .then(res => res.data);
      return response.data;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const detailProduct = createAsyncThunk(
  'product/detailProduct',
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await apiClient.handleRequest({
        method: 'get',
        url: `${detailProductURI(id)}`,
      });
      dispatch(
        setDefaultValue({
          ...response.data.data,
        }),
      );
      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const upsertProduct = createAsyncThunk(
  'product/upsertProduct',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      await apiClient.handleRequest({
        method: data.id ? 'put' : 'post',
        url: `${detailProductURI(data.id)}`,
        data: data.data,
      });
      if (data.id) {
        dispatch(setValue({ show: true, body: 'Cập nhật thành công', color: 'success' }));
      } else {
        dispatch(setValue({ show: true, body: 'Tạo mới thành công', color: 'success' }));
      }

      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      await apiClient
        .handleRequest({
          method: 'delete',
          url: `${detailProductURI(data.id)}`,
        })
        .then(res => res.data);
      dispatch(fetchProducts({ page: 1 }));
      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// Then, handle actions in your reducers:
const productSlice = createSlice({
  name: 'product',
  initialState: {
    entities: [],
    loading: 'idle',
    saving: 'idle',
    saving_id: 0,
    currentRequestId: undefined,
    error: null,
    currentPage: 1,
    totalPage: 1,
    total: 0,
  },
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [fetchProducts.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchProducts.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        const { items, page, total, limit } = action.payload;
        state.loading = 'idle';
        state.entities = items;
        state.currentPage = page;
        state.totalPage = Math.ceil(total / limit);
        state.currentRequestId = undefined;
        state.total = total;
      }
    },
    [fetchProducts.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [upsertProduct.pending]: (state, action) => {
      if (state.saving === 'idle') {
        state.saving = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    [upsertProduct.fulfilled]: (state, action) => {
      state.saving = 'idle';
    },
    [upsertProduct.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.saving === 'pending' && state.currentRequestId === requestId) {
        state.saving = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { setItem, resetData } = productSlice.actions;

export default productSlice.reducer;
