/* eslint-disable no-nested-ternary */
import CIcon from '@coreui/icons-react';
import { CInput } from '@coreui/react';
import React, { useState, useEffect, useMemo } from 'react';

function MediaItemDetail({ data, onDelete, onChange, mediaType }) {
  const [title, setTitle] = useState(data.title);
  const url = useMemo(() => {
    if (typeof data.url !== 'string') {
      return URL.createObjectURL(data.url);
    }
    return data.url;
  }, [data.url]);

  useEffect(() => {
    setTitle(data.title);
  }, [data.title]);

  useEffect(() => {
    const handleTimeout = () => {
      if (onChange) onChange(title);
    };
    const timeout = setTimeout(handleTimeout, 500);
    return () => clearTimeout(timeout);
  }, [title]);

  return (
    <div style={{ position: 'relative', marginRight: '20px', marginTop: '10px' }}>
      <CIcon
        style={{
          position: 'absolute',
          top: '-8px',
          right: '-10px',
          cursor: 'pointer',
        }}
        name="cil-x-circle"
        alt="circle"
        onClick={() => onDelete()}
      />
      {mediaType === 0 ? (
        <div>
          <img alt="" style={{ width: '100px', height: 'auto', marginBottom: '1rem' }} src={url} />
          <CInput name="title" onChange={e => setTitle(e.target.value)} value={title || ''} />
        </div>
      ) : mediaType === 1 ? (
        <video width="400" controls preload="metadata">
          <track kind="captions" />
          <source src={`${url}#t=0.5`} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      ) : (
        <p>{data.url.name}</p>
      )}
    </div>
  );
}

export default MediaItemDetail;
