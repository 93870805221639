/* eslint-disable radix */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from 'src/api/api-client';
import { detailPostCategoryURI, postCategoryURI } from '../api';
import { setValue } from './MessageSlice';
import { setDefaultValue } from './PostCategoryItemSlice';

// First, create the thunk
export const fetchPostCategory = createAsyncThunk(
  'postCategory/fetchPostCategory',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiClient
        .handleRequest({
          method: 'get',
          url: `${postCategoryURI}`,
          params: { ...params, show_all: true },
        })
        .then(res => res.data);
      return response.data;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const detailPostCategory = createAsyncThunk(
  'postCategory/detailPostCategory',
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await apiClient.handleRequest({
        method: 'get',
        url: `${detailPostCategoryURI(id)}`,
      });
      dispatch(
        setDefaultValue({
          ...response.data.data,
        }),
      );
      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const upsertPostCategory = createAsyncThunk(
  'postCategory/upsertPostCategory',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      await apiClient.handleRequest({
        method: data.id ? 'put' : 'post',
        url: `${detailPostCategoryURI(data.id)}`,
        data: data.data,
      });
      if (data.id) {
        dispatch(setValue({ show: true, body: 'Cập nhật thành công', color: 'success' }));
      } else {
        dispatch(setValue({ show: true, body: 'Tạo mới thành công', color: 'success' }));
      }

      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePostCategory = createAsyncThunk(
  'postCategory/deletePostCategory',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      await apiClient
        .handleRequest({
          method: 'delete',
          url: `${detailPostCategoryURI(data.id)}`,
        })
        .then(res => res.data);
      dispatch(fetchPostCategory({ page: 1 }));
      return true;
    } catch (err) {
      dispatch(setValue({ show: true, body: err.message }));
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// Then, handle actions in your reducers:
const postCategorySlice = createSlice({
  name: 'postCategory',
  initialState: {
    entities: [],
    loading: 'idle',
    saving: 'idle',
    saving_id: 0,
    currentRequestId: undefined,
    error: null,
    currentPage: 1,
    totalPage: 1,
    total: 0,
  },
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [fetchPostCategory.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchPostCategory.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        const { items, page, total, limit } = action.payload;
        state.loading = 'idle';
        state.entities = items;
        state.currentPage = page;
        state.totalPage = Math.ceil(total / limit);
        state.currentRequestId = undefined;
        state.total = total;
      }
    },
    [fetchPostCategory.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [upsertPostCategory.pending]: (state, action) => {
      if (state.saving === 'idle') {
        state.saving = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    [upsertPostCategory.fulfilled]: (state, action) => {
      state.saving = 'idle';
    },
    [upsertPostCategory.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.saving === 'pending' && state.currentRequestId === requestId) {
        state.saving = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { setItem, resetData } = postCategorySlice.actions;

export default postCategorySlice.reducer;
