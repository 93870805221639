import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './slices/AuthSlice';
import SidebarReducer from './slices/SidebarSlice';
import JobReducer from './slices/JobSlice';
import CategoryReducer from './slices/JobCatergoriesSlice';
import MessageReducer from './slices/MessageSlice';
import JobItemReducer from './slices/JobItemSlice';
import cvReducer from './slices/CVSlice';
import blockReducer from './slices/BlockSlice';
import bodReducer from './slices/BodSlice';
import bodItemReducer from './slices/BodItemSlice';
import newReducer from './slices/NewSlice';
import newItemReducer from './slices/NewItemSlice';
import serviceReducer from './slices/ServiceSlice';
import serviceItemReducer from './slices/ServiceItemSlice';
import serviceCategoryReducer from './slices/ServiceCategorySlice';
import potentialCandidateReducer from './slices/PotentialCandidateSlice';
import configReducer from './slices/ConfigSlice';
import introductionItemReducer from './slices/IntroductionItemSlice';
import introductionReducer from './slices/IntroductionSlice';
import ReviewSlice from './slices/ReviewSlice';
import ReviewItemSlice from './slices/ReviewItemSlice';
import ActivitySlice from './slices/ActivitySlice';
import ActivityItemSlice from './slices/ActivityItemSlice';
import RegistrationSlice from './slices/RegistrationSlice';
import RegistrationItemSlice from './slices/RegistrationItemSlice';
import NewsSlice from './slices/NewsSlice';
import NewsItemSlice from './slices/NewsItemSlice';
import BusinessFieldSlice from './slices/BusinessFieldSlice';
import BusinessFieldItemSlice from './slices/BusinessFieldItemSlice';
import ContactSlice from './slices/ContactSlice';
import ContactItemSlice from './slices/ContactItemSlice';
import PostCategorySlice from './slices/PostCategorySlice';
import PostCategoryItemSlice from './slices/PostCategoryItemSlice';
import MediaSlice from './slices/MediaSlice';
import MediaItemSlice from './slices/MediaItemSlice';
import ProductCategorySlice from './slices/ProductCategorySlice';
import ProductCategoryItemSlice from './slices/ProductCategoryItemSlice';
import ProductSlice from './slices/ProductSlice';
import ProductItemSlice from './slices/ProductItemSlice';

export default configureStore({
  reducer: {
    auth: AuthReducer,
    sidebar: SidebarReducer,
    job: JobReducer,
    category: CategoryReducer,
    message: MessageReducer,
    jobItem: JobItemReducer,
    cv: cvReducer,
    block: blockReducer,
    bod: bodReducer,
    bodItem: bodItemReducer,
    new: newReducer,
    newItem: newItemReducer,
    service: serviceReducer,
    serviceItem: serviceItemReducer,
    review: ReviewSlice,
    reviewItem: ReviewItemSlice,
    activity: ActivitySlice,
    activityItem: ActivityItemSlice,
    registration: RegistrationSlice,
    registrationItem: RegistrationItemSlice,
    news: NewsSlice,
    newsItem: NewsItemSlice,
    product: ProductSlice,
    productItem: ProductItemSlice,
    businessField: BusinessFieldSlice,
    businessFieldItem: BusinessFieldItemSlice,
    media: MediaSlice,
    mediaItem: MediaItemSlice,
    postCategory: PostCategorySlice,
    postCategoryItem: PostCategoryItemSlice,
    productCategory: ProductCategorySlice,
    productCategoryItem: ProductCategoryItemSlice,
    contact: ContactSlice,
    contactItem: ContactItemSlice,
    serviceCategory: serviceCategoryReducer,
    potentialCandidate: potentialCandidateReducer,
    config: configReducer,
    introductionItem: introductionItemReducer,
    introduction: introductionReducer,
  },
});
