/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CInputFile,
  CInputCheckbox,
  CButton,
  CForm,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import { detailActivity, upsertActivity } from 'src/slices/ActivitySlice';
import { resetData } from 'src/slices/ActivityItemSlice';

const ActivitiesDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const item = useSelector(state => state.activityItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = useFormik({
    initialValues: {
      title: item.title || '',
      status: item.status || 'inactive',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      status: Yup.string().required('Vui lòng điền mô tả'),
    }),
    onSubmit: async vals => {
      let { image } = item;
      if (selectedImage) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', selectedImage);
        const uploadRes = await apiClient.handleRequest({
          method: 'post',
          url: `${uploadFileURI}`,
          data: uploadFormData,
        });
        image = uploadRes.data.data;
      }
      const res = await dispatch(
        upsertActivity({ id: id === 'create' ? '' : id, data: { ...vals, image } }),
      );
      if (res.payload === true) {
        history.push(`/activities`);
      }
    },
  });
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/activities`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailActivity(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);
  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo hoạt động</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="avatar">Tải ảnh</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="image"
                      onChange={e => {
                        imageChange(e);
                      }}
                      accept="image/*"
                    />
                    <CLabel htmlFor="image" variant="custom-file">
                      {selectedImage?.name || 'Choose file'}
                    </CLabel>
                  </CFormGroup>
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title">Tiêu đề</CLabel>
                  <CInput
                    id="title"
                    name="title"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title || ''}
                  />
                  {touched.title && errors.title ? (
                    <small className="text-danger">{errors.title}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                {selectedImage && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={URL.createObjectURL(selectedImage)}
                      alt=""
                    />
                  </div>
                )}
                {!selectedImage && item.image && (
                  <div>
                    <img style={{ width: '-webkit-fill-available' }} src={item.image} alt="" />
                  </div>
                )}
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 'active' ? 1 : 0}
                    onChange={e =>
                      setData(e.target.name, values.status === 'active' ? 'inactive' : 'active')
                    }
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default ActivitiesDetail;
