import { createSlice } from '@reduxjs/toolkit';
import MediaTypes from 'src/enums/MediaTypes';

const initialState = {
  mediaType: MediaTypes.IMAGE.value,
  mediaItems: [],
  thumbnailUrl: '',
  title: '',
  status: '',
};
export const mediaItemSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload, mediaItems: [...action.payload.mediaItems] };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = mediaItemSlice.actions;

export default mediaItemSlice.reducer;
